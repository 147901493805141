<template>
	<div class="h-full flex flex-col relative">
		<TopBar :showMenu="true"/>
		<AppTitle title="Notifications"/>
		<div class="pt-8 overflow-y-scroll flex-grow">

			<div class="space-y-4">

				<div v-if="notifications.length === 0" class="block px-4 py-2 text-sm text-gray-700 rounded-b-md">
					<p class="pb-2 font-gothic text-red-500 text-lg">Welcome to Hungry+!</p>
					<p class="pb-1">You will see notifications here as soon as you start interacting with the app and your friends.</p>
				</div>
				<div v-if="notifications.length">
					<div 
						class="flex bg-white rounded-xl border border-gray-100 py-4 px-4 mb-4 cursor-pointer items-center" 
						@click="readNotification(notification)"
						v-for="notification in notifications"
						:key="notification.id"
					>
						<div class="flex-1">
							<p class="text-sm text-gray-700" v-html="notification.text"></p>
							<p class="text-xs text-gray-400">{{ notification.created_diff }}</p>
						</div>
						<font-awesome-icon icon="fa-solid fa-circle" class="text-xs flex-0 text-red-500" v-if="notification.read===0"/>
					</div>
				</div>

		</div>
	</div>
</div>
</template>

<script type="text/javascript">
	import TopBar from '@/components/TopBar.vue';
	import AppTitle from '@/components/AppTitle.vue';
	export default {
		components: {
			TopBar,
			AppTitle,
		},
		data(){
			return {

			}
		},
		methods: {
			readNotification(notification){
				this.$store.dispatch('readNotification', notification.uuid);
				if (notification.meta){
					let meta = JSON.parse(notification.meta)
					if (meta && meta.screen && this.$router.currentRoute.name !== meta.screen){
						let new_route = {
							name: meta.screen
						};
						if (meta.params){
							new_route.params = meta.params;
						}
						this.$router.push(new_route);
					}
				}
			}
		},
		computed: {
			notifications(){
				return this.$store.state.notifications;
			}
		}
	}
</script>